import { watch } from 'vue';
const useAutofill = (speaker1, speaker2, proximity, hasSecondGroup) => {
    watch(speaker1, speaker => {
        if (!speaker || !proximity.value || !hasSecondGroup.value)
            return;
        if (speaker == 'both') {
            speaker2.value = 'both';
        }
        else if (proximity.value == 'answer') {
            speaker2.value = speaker == 'agent' ? 'customer' : 'agent';
        }
        else {
            speaker2.value = speaker;
        }
    });
    watch(speaker2, speaker => {
        if (!speaker || !proximity.value || !hasSecondGroup.value)
            return;
        if (speaker === 'both') {
            speaker1.value = 'both';
        }
        else if (proximity.value == 'answer') {
            speaker1.value = speaker == 'agent' ? 'customer' : 'agent';
        }
        else {
            speaker1.value = speaker;
        }
    });
    watch(proximity, newProximity => {
        if (!newProximity || !hasSecondGroup.value)
            return;
        if (speaker1.value == 'both') {
            speaker2.value = 'both';
        }
        else if (speaker2.value == 'both') {
            speaker1.value = 'both';
        }
        else if (newProximity == 'answer') {
            if (speaker1.value) {
                console.log(speaker1, speaker1.value == 'agent' ? 'customer' : 'agent');
                speaker2.value = speaker1.value == 'agent' ? 'customer' : 'agent';
            }
            else if (speaker2.value) {
                speaker1.value = speaker2.value == 'agent' ? 'customer' : 'agent';
            }
        }
        else {
            if (speaker1.value) {
                speaker2.value = speaker1.value;
            }
            else if (speaker2.value) {
                speaker1.value = speaker2.value;
            }
        }
    });
};
export default useAutofill;
